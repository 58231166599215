import React from 'react';
import * as style from './fellows.module.scss';
import { makeAnchor } from '../../components/article/faqList';
import { navigate } from 'gatsby-link';

const Fellows = props => (
  <div
  	id={props.id}
  >
		<div className={style.bgWhite}>
			<div className={style.container}>
				<div id={makeAnchor('Fellows')} className={style.fellowsStaff}>
					<h2 className={`${style.sectionHeading}`}>
						MEET OUR FELLOWS
					</h2>
					<div className={style.fellowsStaffInset}>
						{props.fellows && props.fellows.length > 0 && props.fellows.map(function(item, i) {
							return (
								<div className={style.fellowItem} data-sort={item.node.author_sort}>
									<div
										onClick={() => {
											navigate(
												'/fellows/'+item.node.slug,
												{ state: {
													staff_type: item.node.staff_type,
													link_back: props.linkBack,
													link_back_name: props.linkBackName
												}}
											)
										}}
										className={style.bioImage} style={{ backgroundImage: 'url('+item.node.bio_image+')'}}
									>
										<br />
									</div>
									<h3
										onClick={() => {
											navigate(
												'/fellows/'+item.node.slug,
												{ state: {
													staff_type: item.node.staff_type,
													link_back: props.linkBack,
													link_back_name: props.linkBackName
												}}
											)
										}}
										className={style.staffName}
									>
										{item.node.title}
									</h3>
									<p
										onClick={() => {
											navigate(
												'/fellows/'+item.node.slug,{ state: {
													staff_type: item.node.staff_type,
													link_back: props.linkBack,
													link_back_name: props.linkBackName
												}}
											)
											
										}}
										className={style.staffPosition}
									>
										{item.node.position}
									</p>
									
									<div className={style.socialWrap}>
										{item.node.email &&
											<a
												href={`mailto:${item.node.email}`}
												className={`icon-fa-all black-orange-hover fas fa-envelope`}
												target="_blank"
												rel="noopener"
											>
												<span>Email</span>
											</a>
										}
										{item.node.twitter_link &&
											<a
												href={item.node.twitter_link}
												className={`icon-social black-orange-hover`}
												target="_blank"
												rel="noopener"
											>
												<div className={`icon-twitter x-black`}></div>
											</a>
										}
										{item.node.linkedin_link && item.node.linkedin_link.uri &&
											<a
												href={item.node.linkedin_link.uri}
												className={`icon-social black-orange-hover fas fa-linkedin-in`}
												target="_blank"
												rel="noopener"
											>
												<span>LinkedIn</span>
											</a>
										}
										{item.node.bluesky_link && item.node.bluesky_link.uri &&
											<a
												href={item.node.bluesky_link.uri}
												className={`icon-social black-orange-hover fas fa-bluesky`}
												target="_blank"
												rel="noopener"
											>
												<span>Bluesky</span>
											</a>
										}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
  </div>
);

export default Fellows;
